body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68D391;
}

.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68D391;
}

/*Login Design - Raf*/
@import 'assets/css/mdc.floating-label.css';
@import 'assets/css/mdc.line-ripple.css';
@import 'assets/css/mdc.notched-outline.css';
@import 'assets/css/mdc.textfield.css';

.mdc-text-field--filled .mdc-line-ripple::after
{
	border-bottom-color: #ff7c00;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label
{
	color: #37ADED;
}	

.mdc-text-field .mdc-text-field__input
{
	caret-color: #ff7c00;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #ff7c00;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input
{
	color: #37ADED;
}

.Login_Background
{
	background: #FFF url('assets/img/bg.png') no-repeat;
	background-size: 100% 100%;
	width: 100%;
	height: 100%;
}

.Login_MDC
{
	border: 1px solid #CCC;
	border-radius: 50px;
	padding-left: 20px;
	width: 100%;
}

.Login_Button
{
	background-color: #ff7c00;
}


.login-container
{
	border: 1px solid #CCC;
	border-radius: 50px;
	width: 1000px;
	height: 550px;
	background-color: #FFF;
}

.login-border
{
	border: 1px solid #CCC;
	border-radius: 16px;
	background-color: #FFF;
	margin:0 auto;
}

.login-left-container
{
	height: 440px;
	width:350px;
	margin: auto;
}

.login-right-subtitle-left-1
{
	text-align: center;
	font-size: 18px;
	font-style: bold;
	color: #37ADED;
	margin-bottom: 20px;
	position: absolute;
	top: 150px;
	left: 180px;
	font-weight: bold;
}

.login-right-background
{
	height: 100%;
	width: 100%;
	opacity: 0.7;
	background: #FFF url('assets/img/bg1.jpg') no-repeat;
	background-size: 100% 100%;
	border-right: 1px solid #CCC;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;

}

.login-right-background-image
{
	width: 410px;
	padding-top: 130px;
	padding-left: 110px;
}

.login-right-background-image-overlay
{
	background: #37ADEDDE;
	overflow: hidden;
	height: 100%;
	position: relative;
	border-right: 1px solid #CCC;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}

.login-right-subtitle
{
	text-align: center;
	font-size: 15px;
	font-style: bold;
	color: white;
	text-shadow: 0 0 5px #FFF;
	margin-bottom: 20px;
	position: absolute;
	top: 220px;
	left: 130px;
}

/*.mdc-text-field--filled
{
    height: 36px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled)
{
	--mdc-ripple-fg-size: 159px;
    --mdc-ripple-fg-scale: 1.77069;
    --mdc-ripple-fg-translate-start: 136.859px, -48.5px;
    --mdc-ripple-fg-translate-end: 53.3516px, -51.5px;
    background-color: whitesmoke;

}*/
/*Login Design - Raf*/